import { useReducer, useMemo } from 'react';
import authService from 'services/auth/service';

import { useCarriers } from 'api/v2/carriers';
import { useDriverContainers } from 'api/v2/containers';
import {
  carrierOptions,
  containerOptions
} from './helpers';


const user = authService.getUser();

export const useCarrierOptions = () => {
  const { data: carriers } = useCarriers();
  return carrierOptions(carriers, user);
};

// eslint-disable-next-line
const editDriverReducer = (state, action) => {
  switch (action.type) {
    case 'set_value':
      return {
        ...state,
        [action.name]: action.payload
      };
    default:
      return state;
  }
}

export const useEditDriverForm = (driverInfo) => {
  const formattedDriverInfo = {
    districtId: driverInfo.district_id,
    homeDistrict: driverInfo.home_district,
    phone: driverInfo.contact_no,
    email: driverInfo.email,
    selectedDistricts: driverInfo.districts,
    equipment: driverInfo.equipment,
    carrierId: driverInfo.carrier_id,
    driverId: driverInfo.id,
    truck: driverInfo.truck_no,
    trailer: driverInfo.trailer_no,
    containers: driverInfo.containers,
    rating: driverInfo.rating,
    percentage: driverInfo.pay_percentage,
    longitude: driverInfo.longitude,
    latitude: driverInfo.latitude,
    name: driverInfo.name,
    status: driverInfo.status,
  }
  const [formState, dispatch] = useReducer(editDriverReducer, formattedDriverInfo);

  const handlers = useMemo(() => ({
    setValue: (value, name) => dispatch({ type: 'set_value', name, payload: value }),
    reset: () => dispatch({ type: 'reset' }),
  }), [dispatch]);

  return [formState, handlers];
}

export const useContainerOptions = (driverId, districtIds) => {
  const { data: containers } = useDriverContainers(driverId, districtIds);
  return containerOptions(containers);
}
