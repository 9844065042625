import { cloneDeep } from 'lodash';
import moment from 'moment';
import authService from 'services/auth/service';
import multiSelectOptions from 'utils/multiSelectOptions';

export const billingProfileDisabled = (customerId, isDraftJob, operators) => {
  if (!isDraftJob) {
    return true;
  }

  if (
    authService.getUser().role === 6 ||
    Number(authService.getCompany()) === Number(customerId)
  ) {
    return false;
  }

  if (
    operators?.some(
      operator => Number(operator.id) === Number(authService.getCompany()),
    )
  ) {
    return false;
  }

  return true;
};

export const formatJobDetails = jobDetails => {
  const job = jobDetails.details;
  const {
    storage,
    tags,
    operators,
    sandTicketDesign,
    districts,
    jobDesign,
    phoneNumbers,
  } = jobDetails;

  const info = {
    name: job.jobName,
    startDate: moment(job.startDate).local(),
    endDate: moment(job.endDate).local(),
    isTestJob: job.isTestJob,
    requireTrackedContainers: Boolean(job.requireTrackedContainers),
    useCommodityPrice: Boolean(job.useCommodityPrice),
    numberOfStages: job.potentialTS1,
    directions: job.directions,
    pumpTime: job.pumpTime,
    wirelineTime: job.wirelineTime,
    assignClosesOnly: job.assignClosesOnly,
    sendCompletionCodeToDriver: Boolean(job.sendCompletionCodeToDriver),
    sandTicketDesign: [],
    districtIds: multiSelectOptions(districts, 'id', 'name'),
    loaded: true,
    operationType: job.operationType,
    alertsEnabled: Boolean(job.alertsEnabled),
    equipment: job.equipments,
    stagingSite: job.stagingSite,
    totalWeightPerJob: job.totalWeightPerJob,
    targetStagesPerDay: job.targetStagesPerDay,
    customerJobId: job.customerJobId,
    navId: job.nav_id,
    customerId: job.customerId,
    wellSiteId: job.location,
    minDriversAmount: job.minDriversAmount,
    demurrageAlert: job.demurrageAlert,
    messagesAllowed: job.messagesAllowed,
    billingProfileId: job.billingProfileId,
    operators,
    customerProjectId: job.customerProjectId,
    isDraftJob: job.isDraft,
    tags,
    jobDesign,
    phoneNumbers: phoneNumbers.map(item => ({
      number: item,
    })),
    certificates: multiSelectOptions(
      job.certificates,
      'certificateId',
      'certificateName',
    ),
  };

  if (storage && storage.find(s => s.storage_type === '1' && s.storage_id)) {
    info.storageIds = storage
      .filter(s => s.storage_type === '1' && s.storage_id)
      .map(s => ({
        storage_id: s.storage_id,
        id: s.id,
      }));
  }

  sandTicketDesign.forEach(item => {
    if (item.isRequired === 1) {
      info.sandTicketDesign.push({
        value: item.sandTicketTypeId,
        label: item.name,
      });
    }
  });

  return info;
};

export const formatEditData = editData => {
  const data = cloneDeep(editData);

  data.isDraft = Number(data.isADraftSave ?? 0);
  data.useCommodityPrice = Number(data.useCommodityPrice);
  data.assignClosesOnly = Number(data.assignClosesOnly);
  data.sendCompletionCodeToDriver = Number(data.sendCompletionCodeToDriver);
  data.alertsEnabled = Number(data.alertsEnabled);
  data.sandTicketDesign = data.sandTicketDesign.map(item => ({
    sandTicketTypeId: item.value,
  }));
  data.certificates = data.certificates.map(item => item.value);
  data.districtIds = Array.from(data.districtIds.map(item => item.value));
  data.equipment = data.equipment.map(e => Number(e));
  data.totalWeightPerJob = Number(data.totalWeightPerJob);
  data.targetStagesPerDay = Number(data.targetStagesPerDay);

  // Prevents already has job permissions error, since this does an upsert but only deletes 'shared' before validating new ones
  if (data?.sharedCreator?.id) {
    data.additionalOperators = data.additionalOperators.filter(
      op => op.entityType !== 3 || op.id !== data.sharedCreator.id
    );
  }

  if (!data.totalWeightPerJob) {
    delete data.totalWeightPerJob;
  }
  if (!data.targetStagesPerDay) {
    delete data.targetStagesPerDay;
  }
  if (!data.stagingSite) {
    delete data.stagingSite;
  }
  if (!data.targetStagesPerDay) {
    delete data.targetStagesPerDay;
  }
  if (!data.totalWeightPerJob) {
    delete data.totalWeightPerJob;
  }

  data.phoneNumbers = data.messagesAllowed
    ? data.phoneNumbers.map(item => `${item.code}${item.number}`)
    : undefined;
  data.messagesAllowed = Number(data.messagesAllowed);
  data.isTestJob = Number(data.isTestJob);
  data.storageConfig = data.storageConfig.map(item => ({
    ...item,
    storage_id: item.storage_id?.length
      ? String(item.storage_id ?? '')
      : undefined,
    volume: Number(item.volume ? item.volume : 1),
    amount: Number(item.amount ? item.amount : 1),
  }));

  data.jobDesign = data.jobDesign.map(item => ({
    ...item,
    minVolume: Number(item.minVolume),
    loadWeight: Number(item.loadWeight),
    volume: Number(item.volume),
    sandTypeId: Number(item?.commodity?.id ?? item.sandTypeId)
  }));

  return data;
};
